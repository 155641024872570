import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";

import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getNotifications } from "../../../../redux/actions/room";
import { formatDateAndHour } from "../../../../utils/datetime";

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ theme, esLayoutMobile }) => ({
  display: "flex",
  flexDirection: "column",
  height: !esLayoutMobile ? "100%" : "100%",
  marginBottom: 5,
  justifyContent: esLayoutMobile ? "flex-end" : "space-between",
}));

const WelcomeText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ theme, esLayoutMobile }) => ({
  fontSize: esLayoutMobile ? 12 : 18,
  margin: esLayoutMobile ? 5 : 15,
  textAlign: "center",
  backgroundColor: "#f2f0f2",
  padding: 10,
  borderRadius: 10,
  marginTop: esLayoutMobile ? 0 : 145,
  border: "2px solid #636262",
}));

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ esLayoutMobile }) => ({
  backgroundColor: "#f2f0f2",
  width: "100%",
  height: "100%",
  borderRadius: 10,
  boxShadow: 2,
  marginTop: 15,
  marginBottom: esLayoutMobile ? 25 : 45,
}));

const CardHeader = styled(Box)({
  backgroundColor: "rgba(42, 93, 247, 0.75)",
  borderRadius: "10px 10px 0px 0px",
  padding: 5,
});

const CardHeaderText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ theme, esLayoutMobile }) => ({
  color: "white",
  marginLeft: 20,
  fontSize: esLayoutMobile ? 14 : 16,
  fontWeight: "bold",
}));

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ esLayoutMobile }) => ({
  height: !esLayoutMobile ? "100%" : "90%",
  marginBottom: !esLayoutMobile ? 20 : 0,
  padding: 5,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
}));

const MessagesContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ esLayoutMobile }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.76)",
  border: "1px solid rgba(189, 182, 182, 0.27)",
  borderRadius: 10,
  padding: 5,
  marginBottom: !esLayoutMobile ? 15 : 5,
  height: "100%",
  overflow: "auto",
  maxHeight: "100%",
}));

const MessageBox = styled(Box)({
  backgroundColor: "#f2f0f2",
  borderRadius: 10,
  padding: 5,
  margin: 5,
  boxShadow: 1,
});

const MessageText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "esLayoutMobile",
})(({ esLayoutMobile }) => ({
  fontSize: esLayoutMobile ? 12.5 : 14,
}));

const MessageTime = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "10px",
  color: "#777",
});

const ProfesionalNotifications = () => {
  const esLayoutMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const idRoom = searchParams.get("id");

  const notifications = useSelector((state) => state.room.notifications);
  const {
    apellidoNombrePaciente,
    apellidoNombreProfesional,
    fechaHoraAgendada,
    duracionAgendada,
  } = useSelector((state) => state.call.call.data);

  useEffect(() => {
    dispatch(getNotifications(idRoom));
    const intervalId = setInterval(() => {
      dispatch(getNotifications(idRoom));
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch, idRoom]);

  return (
    <Container esLayoutMobile={esLayoutMobile}>
      <WelcomeText variant="h5" esLayoutMobile={esLayoutMobile}>
        Bienvenido/a! {apellidoNombrePaciente.toUpperCase()}. Tu cita está
        programada para el {formatDateAndHour(fechaHoraAgendada).fecha} a las{" "}
        {formatDateAndHour(fechaHoraAgendada).hora}. La duración aproximada es
        de {duracionAgendada} minutos. En breve el Dr/a.{" "}
        {apellidoNombreProfesional.toUpperCase()} se conectará...
      </WelcomeText>
      <StyledCard esLayoutMobile={esLayoutMobile}>
        <CardHeader>
          <CardHeaderText variant="h6" esLayoutMobile={esLayoutMobile}>
            Mensajes del Dr/a. {apellidoNombreProfesional}
          </CardHeaderText>
        </CardHeader>
        <StyledCardContent esLayoutMobile={esLayoutMobile}>
          <MessagesContainer esLayoutMobile={esLayoutMobile}>
            {notifications.loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : notifications.data.length > 0 ? (
              notifications.data.map((msg) => (
                <MessageBox key={msg.id}>
                  <MessageText variant="body2" esLayoutMobile={esLayoutMobile}>
                    {msg.mensaje}
                    <MessageTime>{msg.hora}</MessageTime>
                  </MessageText>
                </MessageBox>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" align="center">
                No hay notificaciones.
              </Typography>
            )}
          </MessagesContainer>
        </StyledCardContent>
      </StyledCard>
    </Container>
  );
};

export default ProfesionalNotifications;
