import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../../assets/images/marcaAccess.png";
import {
  AvatarList,
  Logo,
  videoContainer,
  viewport,
  viewportMobileReceiveSharing,
} from "../../../../styled/ZoomContainer";
import MediaControlsContainer from "../../buttons/MediaControlsContainer";
import ProfesionalNotifications from "../../profesional/ProfesionalNotifications";
import { RoomContext } from "../context/roomContext";
import { useCanvasDimension } from "../hooks/useCanvasDimension";
import { useGalleryLayout } from "../hooks/useGalleryLayout";
import useHeight from "../hooks/useHeight";
import Avatar from "./Avatar";
import Preview from "./Preview";
import SelfViewContainer from "./SelfViewContainer";
import ShareView from "./ShareView";

const ZoomParticipantContainer = () => {
  const globalTheme = useTheme();

  const salaEsperaContainer = {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "10px 30px",
    height: "100%",
    background: `radial-gradient(circle, ${globalTheme.palette.primary.homeGradient1}, ${globalTheme.palette.primary.homeGradient2})`,
    width: "100%",
    overflow: "hidden",
    borderBottom: `1px solid ${globalTheme.palette.primary.grey}`,
    "& a": {
      textDecoration: "none",
    },

    [globalTheme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
  };

  const enLlamadaContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(to right, ${globalTheme.palette.primary.homeGradient1}, ${globalTheme.palette.primary.homeGradient2})`,
    overflow: "hidden",
    borderBottom: `1px solid ${globalTheme.palette.primary.grey}`,
    "& a": {
      textDecoration: "none",
    },

    [globalTheme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  };
  const zmClient = useContext(RoomContext);
  const mediaStream = zmClient?.getMediaStream();
  const SELF_VIDEO_ID = "self-view-video";
  const isVideoDecodeReady = true;

  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const selfViewRef = useRef(null);
  const shareViewRef = useRef({ selfShareRef: null });

  // Que la altura del contenedor principal sea la altura del viewport.
  // En algunos navegadores mobile, 'height: 100vh' iguala la altura de la pantalla,
  // y no la del viewport. Esto se ve mal cuando la barra superior del navegador
  // se encuentra abierta. Vamos a setear la altura dinámicamente con 'window.innerHeight'.
  // Consecuentemente, esto se va a ver bien en navegadores mobile sin importar si
  // la barra de URL está abierta o no.
  const height = useHeight();

  const canvasDimension = useCanvasDimension(
    mediaStream,
    canvasRef,
    isRecieveSharing,
  );

  const participanteLocal = zmClient?.getCurrentUserInfo();
  const haFinalizadoSesion = !zmClient.getSessionInfo()?.isInMeeting;

  const esPaciente = participanteLocal?.userIdentity === "Paciente";
  const noIngresoProfesional = zmClient
    .getAllUser()
    .every((user) => user.userIdentity !== "Profesional");
  const deberiaEstarEnSalaEspera = esPaciente && noIngresoProfesional;

  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    deberiaEstarEnSalaEspera,
  );

  const tieneViewportPocaAltura = useMediaQuery((theme) =>
    theme.breakpoints.down("401px"),
  );
  const hayParticipantesRemotos =
    visibleParticipants.filter((u) => u.userId !== participanteLocal?.userId)
      .length >= 1;
  const esLayoutMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // Tenemos 2 situaciones especiales:
  // 1. Siguiendo el ejemplo de MLNet, cuando el iframe de la llamada es
  // demasiado corto en altura, se debe dejar de renderizar al participante
  // local y solamente dejar el remoto.
  // 2. Algunos dispositivos mobiles son tan pequeños que las
  // camaras de dos participantes + el share-view no entran.
  // Por eso escondemos la camara del participante local.
  const noRenderizarParticipanteLocal =
    (tieneViewportPocaAltura && hayParticipantesRemotos) ||
    (esLayoutMobile && isRecieveSharing);

  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === participanteLocal?.userId,
  );
  const otherUserIndex = visibleParticipants.findIndex((user) => user.userId !== participanteLocal?.userId)
  let selfVideoLayout = null;

  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = {
        ...item,
      };
    }
  }
  let otherVideoLayout = null;
  if (otherUserIndex > -1) {
    const item = videoLayout[otherUserIndex];
    if (item && canvasDimension) {
      otherVideoLayout = {
        ...item,
      }
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", () => zmClient.leave());

    return () => {
      window.removeEventListener("beforeunload", () => zmClient.leave());
      zmClient.leave();
    };
  }, [zmClient]);

  useEffect(() => {
    if (haFinalizadoSesion) {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    }
  }, [zmClient, haFinalizadoSesion, videoRef]);
  return (
    <Box
      sx={{
        height: height,
        ...(deberiaEstarEnSalaEspera
          ? salaEsperaContainer
          : enLlamadaContainer),
      }}>
      <Box
        sx={(theme) => ({
          ...(esLayoutMobile && isRecieveSharing
            ? viewportMobileReceiveSharing
            : {
              ...viewport,
              backgroundColor: (prop) =>
                deberiaEstarEnSalaEspera ? "transparent" : "rgb(0, 0, 0)",

              [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 5,
              },
            }),
        })}>
        <ShareView
          ref={shareViewRef}
          onRecieveSharingChange={setIsRecieveSharing}
        />
        <Box
          sx={(theme) => ({
            ...videoContainer,
          })}>
          {deberiaEstarEnSalaEspera && (
            <Logo src={logo} alt="Logo AccessInformática" />
          )}
          {/* el video-player-container es donde se van a renderizar todos */}
          {/* los videos de los demas participantes */}
          <canvas ref={canvasRef} style={{ width: "100%", height: "100%", position: "absolute", }} />
          <video-player-container
            style={otherVideoLayout ? {

              width: `${otherVideoLayout.width}px`,
              height: `${otherVideoLayout.height}px`,
              left: `${otherVideoLayout.x}px`,
              top: `${otherVideoLayout.y}px`,
              backgroundColor: "transparent",
              position: "absolute",
            } : { display: "none" }}
            ref={videoRef}
          />

          <SelfViewContainer
            id={SELF_VIDEO_ID}
            ref={selfViewRef}
            style={
              selfVideoLayout
                ? {
                  display: noRenderizarParticipanteLocal ? "none" : "block",
                  width: `${selfVideoLayout.width}px`,
                  height: `${selfVideoLayout.height}px`,
                  top:
                    esLayoutMobile && deberiaEstarEnSalaEspera
                      ? `${selfVideoLayout.y + 70}px`
                      : `${selfVideoLayout.y}px`,
                  left: `${selfVideoLayout.x}px`,
                  pointerEvents: "none",
                }
                : { display: "none" }
            }
          />

          <AvatarList>
            {visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null;
              }

              // como estamos iterando sobre todos los usuarios, queremos
              // saber si justo este es el participante local para
              // no renderizarlo
              const esAvatarParticipanteLocal =
                participanteLocal?.userId === user.userId;
              if (noRenderizarParticipanteLocal && esAvatarParticipanteLocal) {
                return null;
              }

              const dimension = videoLayout[index];
              const { width, height, x, y } = dimension;
              const posicionTop =
                esLayoutMobile && deberiaEstarEnSalaEspera
                  ? `${y + 70}px`
                  : `${y}px`;

              return (
                <div key={user.userId}>
                  <Avatar
                    participant={user}
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      top: posicionTop,
                      left: `${x}px`,
                    }}
                    tieneViewportPocaAltura={tieneViewportPocaAltura}
                    esParticipanteLocal={
                      participanteLocal?.userId === user.userId
                    }
                  />

                  {!esLayoutMobile && deberiaEstarEnSalaEspera && <Preview />}
                </div>
              );
            })}
          </AvatarList>
        </Box>
        {deberiaEstarEnSalaEspera && <ProfesionalNotifications />}
      </Box>
      <MediaControlsContainer
        room={zmClient}
        selfShareCanvas={shareViewRef.current?.selfShareRef}
        deberiaEstarEnSalaEspera={deberiaEstarEnSalaEspera}
        selfViewRef={selfViewRef}
      />
    </Box>
  );
};

export default ZoomParticipantContainer;
