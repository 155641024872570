import { forwardRef } from "react";

const SelfViewContainer = forwardRef(({ style }, ref) => {
  return (
    <video-player-container
      style={{
        position: "absolute",
        width: style.width,
        height: style.height,
        top: style.top,
        left: style.left,
        zIndex: 7,
        display: style.display,
      }}
      ref={ref}
    />
  );
});

export default SelfViewContainer;
