import { useEffect } from "react";
import { isArrayShallowEqual } from "../../../../../utils/zoomUtils";
import { StylizeVideoCamera } from "../../../../styled/Camera";
import { usePersistFn } from "./usePersistFn";
import { usePrevious } from "./usePrevious";

export function useRenderVideo(
  mediaStream,
  isVideoDecodeReady,
  videoRef,
  layout,
  subscribedVideos,
  participants,
  currentUserId,
) {
  const renderedVideos = subscribedVideos.slice(0, layout.length);
  const previousRenderedVideos = usePrevious(renderedVideos);
  const previousLayout = usePrevious(layout);
  const previousParticipants = usePrevious(participants);
  const previousIsVideoDecodeReady = usePrevious(isVideoDecodeReady);

  useEffect(() => {
    if (videoRef.current && layout && layout.length > 0 && isVideoDecodeReady) {
      const addedSubscribers = renderedVideos.filter(
        (id) => !(previousRenderedVideos || []).includes(id),
      );
      const removedSubscribers = (previousRenderedVideos || []).filter(
        (id) => !renderedVideos.includes(id),
      );
      const unalteredSubscribers = renderedVideos.filter((id) =>
        (previousRenderedVideos || []).includes(id),
      );
      if (removedSubscribers.length > 0) {
        removedSubscribers.forEach(async (userId) => {
          if (userId !== currentUserId) {
            await mediaStream.detachVideo(userId).then((elements) => {
              if (elements) {
                if (Array.isArray(elements)) {
                  elements.forEach((e) => e.remove());
                } else {
                  elements.remove();
                }
              }
            });
          }
        });
      }
      if (addedSubscribers.length > 0) {
        addedSubscribers.forEach(async (userId) => {
          const index = participants.findIndex(
            (user) => user.userId === userId,
          );
          const cellDimension = layout[index];
          if (
            cellDimension && userId !== currentUserId
          ) {
            const { width, height, x, y, quality } = cellDimension;
            let userVideo = await mediaStream?.attachVideo(userId, quality)
            StylizeVideoCamera(userVideo);
            videoRef.current.appendChild(userVideo);
          }
        });
      }
      if (unalteredSubscribers.length > 0) {
        // layout changed
        if (previousLayout && !isArrayShallowEqual(layout, previousLayout)) {
          unalteredSubscribers.forEach(async (userId) => {
            const index = participants.findIndex(
              (user) => user.userId === userId,
            );
            const cellDimension = layout[index];
            if (
              cellDimension && userId !== currentUserId
            ) {
              const { width, height, x, y, quality } = cellDimension;
              if (
                previousLayout?.[index] &&
                previousLayout[index].quality !== quality
              ) {
                let userVideo = await mediaStream?.attachVideo(userId, quality)
                StylizeVideoCamera(userVideo);
                videoRef.current.appendChild(userVideo);
              }
            }
          });
        }
        // the order of participants changed
        const participantsIds = participants.map((user) => user.userId);
        const previousParticipantsIds = previousParticipants?.map(
          (user) => user.userId,
        );
        if (participantsIds.join("-") !== previousParticipantsIds?.join("-")) {
          unalteredSubscribers.forEach(async (userId) => {
            const index = participantsIds.findIndex((id) => id === userId);
            const previousIndex = previousParticipantsIds?.findIndex(
              (id) => id === userId,
            );
            if (index !== previousIndex) {
              const cellDimension = layout[index];
              if (
                cellDimension && userId !== currentUserId
              ) {
                const { width, height, x, y } = cellDimension;
              }
            }
          });
        }
      }
    }
  }, [
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    layout,
    previousLayout,
    participants,
    previousParticipants,
    renderedVideos,
    previousRenderedVideos,
    currentUserId,
  ]);

  useEffect(() => {
    if (
      previousIsVideoDecodeReady === false &&
      isVideoDecodeReady === true &&
      subscribedVideos.length > 0
    ) {
      subscribedVideos.forEach(async (userId) => {
        const index = participants.findIndex((user) => user.userId === userId);
        const cellDimension = layout[index];
        if (
          cellDimension && userId !== currentUserId
        ) {
          const { width, height, x, y, quality } = cellDimension;
          let userVideo = await mediaStream?.attachVideo(userId, quality)
          StylizeVideoCamera(userVideo);
          videoRef.current.appendChild(userVideo);
        }
      });
    }
  }, [
    mediaStream,
    videoRef,
    layout,
    participants,
    subscribedVideos,
    isVideoDecodeReady,
    previousIsVideoDecodeReady,
    currentUserId,
  ]);

  const stopAllVideos = usePersistFn((videoCanvasDOM) => {
    if (subscribedVideos.length > 0) {
      subscribedVideos.forEach((userId) => {
        mediaStream.detachVideo(userId).then((elements) => {
          if (elements) {
            if (Array.isArray(elements)) {
              elements.forEach((e) => e.remove());
            } else {
              elements.remove();
            }
          }
        });
      });
    }
  });

  useEffect(() => {
    const videoCanvasDOM = videoRef.current;
    return () => {
      stopAllVideos(videoCanvasDOM);
    };
  }, [videoRef, stopAllVideos]);
}
