import BackIcon from "@mui/icons-material/ArrowBack";
import { Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "../../../assets/images/error404Image.png";
import ErrorButton from "../errorButton/ErrorButton";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  width: "100%",
  height: "100vh",
  background: `linear-gradient(to right, ${theme.palette.primary.errorGradient1}, ${theme.palette.primary.errorGradient2})`,
  "& a": {
    textDecoration: "none",
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderTop: `1px solid ${theme.palette.primary.grey}`,
  borderBottom: `1px solid ${theme.palette.primary.grey}`,
  margin: "0 auto",
  padding: 20,
  width: "50%",
  [theme.breakpoints.down("md")]: {
    border: "none",
    width: "80%",
  },
}));

const ErrorImage = styled("img")(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down("md")]: {
    width: 400,
  },
}));

const NotFound = () => {
  return (
    <Container>
      <TextContainer>
        <ErrorImage src={notFoundImage} alt="404 error" />
        <Typography
          variant="overline"
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: 600,
            fontSize: { xs: 12, md: 16 },
            color: "secondary.main",
          }}>
          Parece que no hay nada por aquí...
        </Typography>
      </TextContainer>
      <Link to="/">
        <ErrorButton text="Regresar" icon={<BackIcon />} />
      </Link>
    </Container>
  );
};

export default NotFound;
