import { styled } from "@mui/material";
import { MIN_CELL_WIDTH } from "../../utils/layout";
export const Logo = styled("img")(({ theme }) => ({
  maxHeight: 80,
  maxWidth: 300,
  borderBottom: `2px solid ${theme.palette.secondary.main}`,
  marginTop: 15,
}));

export const VideoCanvas = styled("video-player-container")(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  position: "absolute",
}));

export const AvatarList = styled("ul")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  pointerEvents: "none",
  top: 0,
  left: 0,
  padding: 0,
  margin: 0,
}));

export const videoContainer = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  zIndex: 7,
};

export const videoContainerIsSharing = {
  // este width debe ser el mismo que
  // MIN_CELL_WIDTH de layout.js
  width: `${MIN_CELL_WIDTH}px`,
  flexShrink: 0,
};

export const viewportMobileReceiveSharing = {
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  boxSizing: "border-box",
  // gap: 1vw, para que haya espacio entre
  // el selfview y el preview
  gap: "1vw",
  backgroundColor: "rgb(0, 0, 0)",
  justifyContent: "center",
  padding: 5,
};

export const viewport = {
  position: "relative",
  display: "flex",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  boxSizing: "border-box",
  // gap: 1vw, para que haya espacio entre
  // el selfview y el preview
};
