import { useCallback, useEffect, useRef, useState } from "react";
import { useSizeCallback } from "./useSizeCallback";

export function useCanvasDimension(mediaStream, videoRef, isRecieveSharing) {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  const debounceRef = useRef(setDimension);

  const onCanvasResize = useCallback(
    ({ width, height }) => {
      if (videoRef) {
        // eslint-disable-next-line no-useless-call
        debounceRef.current({ width, height });
      }
    },
    [videoRef],
  );

  useSizeCallback(videoRef.current, onCanvasResize);

  // solo ejecutar esto la primera vez
  useEffect(() => {
    if (videoRef.current) {
      const { width, height } = videoRef.current.getBoundingClientRect();
      setDimension({ width, height });
    }
  }, [videoRef]);

  useEffect(() => {
    const { width, height } = dimension;
    // if (videoRef.current) {
    //   videoRef.current.width = width;
    //   videoRef.current.height = height;
    // }
  }, [mediaStream, dimension, videoRef, isRecieveSharing]);

  return dimension;
}
