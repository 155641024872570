import {
  Box,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import ZoomVideo from "@zoom/videosdk";
import { useEffect, useRef, useState } from "react";

let localAudio = ZoomVideo.createLocalAudioTrack();
let allDevices;

const PreviewRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "50%",
  position: "absolute",
  bottom: 50,
  padding: 0,
});

const TestContainer = styled(Card)({
  borderRadius: 10,
  width: "70%",
  marginTop: 15,
});

const TestItem = styled("div")({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#f2f0f2",
});

const TestButton = styled(Button)({
  width: "fit-content",
  fontSize: 12,
  backgroundColor: "rgba(88, 125, 235, 0.64)",
  cursor: "pointer",
});

const Progress = styled("div")({
  margin: 5,
  backgroundColor: "#f2f0f2",
});

const CardHeader = styled(Box)({
  backgroundColor: "rgba(42, 93, 247, 0.75)",
  borderRadius: "10px 10px 0px 0px",
  padding: 5,
});

const CardHeaderText = styled(Typography)({
  color: "white",
  marginLeft: 20,
  fontSize: 16,
  fontWeight: "bold",
});

const mountDevices = async () => {
  allDevices = await ZoomVideo.getDevices();
  const cameraDevices = allDevices.filter((device) => {
    return device.kind === "videoinput";
  });
  const micDevices = allDevices.filter((device) => {
    return device.kind === "audioinput";
  });
  const speakerDevices = allDevices.filter((device) => {
    return device.kind === "audiooutput";
  });
  return {
    mics: micDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    speakers: speakerDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    cameras: cameraDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
  };
};

const Preview = () => {
  const [micList, setMicList] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [outputLevel, setOutputLevel] = useState(0);
  const [inputLevel, setInputLevel] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isPlayingRecording, setIsPlayingRecording] = useState(false);

  const speakerTesterRef = useRef();
  const microphoneTesterRef = useRef();
  const componenteEstaMontado = useRef(true);

  useEffect(() => {
    return () => {
      componenteEstaMontado.current = false;
    };
  }, []);

  useEffect(() => {
    mountDevices().then((devices) => {
      if (componenteEstaMontado.current) {
        setMicList(devices.mics);
        setSpeakerList(devices.speakers);
        if (devices.speakers.length > 0) {
          setActiveSpeaker(devices.speakers[0].deviceId);
        }
        if (devices.mics.length > 0) {
          setActiveMicrophone(devices.mics[0].deviceId);
        }
      }
    });
  }, []);

  const onTestSpeakerClick = () => {
    if (microphoneTesterRef.current) {
      microphoneTesterRef.current.destroy();
      microphoneTesterRef.current = undefined;
    }
    if (isPlayingAudio) {
      speakerTesterRef.current?.stop();
      setIsPlayingAudio(false);
      setOutputLevel(0);
    } else {
      speakerTesterRef.current = localAudio.testSpeaker({
        speakerId: activeSpeaker,
        onAnalyseFrequency: (value) => {
          setOutputLevel(Math.min(100, value));
        },
      });
      setIsPlayingAudio(true);
    }
  };

  const onTestMicrophoneClick = () => {
    if (speakerTesterRef.current) {
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = undefined;
    }
    if (!isPlayingRecording && !isRecordingVoice) {
      microphoneTesterRef.current = localAudio.testMicrophone({
        microphoneId: activeMicrophone,
        speakerId: activeSpeaker,
        recordAndPlay: true,
        onAnalyseFrequency: (value) => {
          setInputLevel(Math.min(100, value));
        },
        onStartRecording: () => {
          setIsRecordingVoice(true);
        },
        onStartPlayRecording: () => {
          setIsRecordingVoice(false);
          setIsPlayingRecording(true);
        },
        onStopPlayRecording: () => {
          setIsPlayingRecording(false);
        },
      });
    } else if (isRecordingVoice) {
      microphoneTesterRef.current?.stopRecording();
      setIsRecordingVoice(false);
    } else if (isPlayingRecording) {
      microphoneTesterRef.current?.stop();
      setIsPlayingRecording(false);
    }
  };

  let microphoneBtn = "Probar microfono";
  if (isRecordingVoice) {
    microphoneBtn = "Grabando";
  } else if (isPlayingRecording) {
    microphoneBtn = "Empezando";
  }

  return (
    <PreviewRoot>
      <TestContainer>
        <CardHeader>
          <CardHeaderText variant="h6">Prueba de altavoz</CardHeaderText>
        </CardHeader>
        <TestItem>
          <TestButton
            color="secondary"
            variant="contained"
            onClick={onTestSpeakerClick}>
            {isPlayingAudio ? "Parar prueba" : "Probar altavoz"}
          </TestButton>
          {speakerList.length ? (
            <Select
              disabled
              variant="standard"
              sx={{ margin: 1 }}
              onChange={(e) => setActiveSpeaker(e.target.value)}
              value={activeSpeaker}>
              {speakerList.map((item) => (
                <MenuItem value={item.deviceId} key={item.deviceId}>
                  {item.label.split("-")[1]}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <CircularProgress
              color="secondary"
              size="25px"
              sx={{ margin: 1 }}
            />
          )}
          <Progress>
            <Typography>Nivel de salida</Typography>
            <LinearProgress
              value={outputLevel}
              variant="determinate"
              color="secondary"
            />
          </Progress>
        </TestItem>
      </TestContainer>
      <TestContainer>
        <CardHeader>
          <CardHeaderText variant="h6">Prueba de microfono</CardHeaderText>
        </CardHeader>
        <TestItem>
          <TestButton
            variant="contained"
            color="secondary"
            onClick={onTestMicrophoneClick}>
            {microphoneBtn}
          </TestButton>
          {micList.length ? (
            <Select
              disabled
              variant="standard"
              sx={{ margin: 1 }}
              onChange={(e) => setActiveMicrophone(e.target.value)}
              value={activeMicrophone}>
              {micList.map((item) => (
                <MenuItem value={item.deviceId} key={item.deviceId}>
                  {item.label.split("-")[1]}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <CircularProgress
              color="secondary"
              size="25px"
              sx={{ margin: 1 }}
            />
          )}
          <Progress>
            <Typography>Nivel de entrada</Typography>
            <LinearProgress
              value={inputLevel}
              variant="determinate"
              color="secondary"
            />
          </Progress>
        </TestItem>
      </TestContainer>
    </PreviewRoot>
  );
};

export default Preview;
