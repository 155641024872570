import { styled } from "@mui/material";
import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { isShallowEqual } from "../../../../../utils/zoomUtils";
import { RoomContext } from "../context/roomContext";
import { usePrevious } from "../hooks/usePrevious";
import { useShare } from "../hooks/useShare";
import { useSizeCallback } from "../hooks/useSizeCallback";
import ShareBar from "./SelfShareView";

const ShareViewType = {
  FitWindow: "fit",
  OriginalSize: "original",
};

const ShareViewContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isRecieveSharing" && prop !== "viewType", // Evitar pasar esta prop al DOM
})(({ theme, isRecieveSharing, viewType }) => ({
  display: isRecieveSharing ? "flex" : "none",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  ...(isRecieveSharing && viewType === ShareViewType.OriginalSize
    ? { display: "block" }
    : {}),
}));

const ShareViewViewport = styled("div", {
  shouldForwardProp: (prop) => prop !== "viewType", // Evitar pasar esta prop al DOM
})(({ theme, viewType }) => ({
  maxWidth: "100%",
  ...(viewType === ShareViewType.OriginalSize
    ? { maxWidth: "initial", cursor: "move" }
    : {}),
  "& .share-view-canvas": {
    height: "100%",
  },
}));

const ShareView = forwardRef((props, ref) => {
  const { onRecieveSharingChange } = props;
  const zmClient = useContext(RoomContext);
  const mediaStream = zmClient?.getMediaStream();
  const selfShareViewRef = useRef(null);
  const shareViewRef = useRef(null);
  const shareViewContainerRef = useRef(null);
  const shareViewViewportRef = useRef(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [shareViewSize, setShareViewSize] = useState({ width: 0, height: 0 });
  const viewType = ShareViewType.FitWindow;

  const previousViewType = usePrevious(viewType);
  const previousShareViewSize = usePrevious(shareViewSize);
  const debounceRef = useRef(_.debounce(setContainerSize, 300));
  const { isRecieveSharing, sharedContentDimension } = useShare(
    zmClient,
    mediaStream,
    shareViewRef,
  );

  const onContainerResize = useCallback(({ width, height }) => {
    if (shareViewContainerRef.current) {
      debounceRef.current({ width, height });
    }
  }, []);

  useEffect(() => {
    if (shareViewContainerRef.current) {
      const { width, height } =
        shareViewContainerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, []);

  useSizeCallback(shareViewContainerRef.current, onContainerResize);

  useEffect(() => {
    if (
      isRecieveSharing &&
      shareViewContainerRef.current &&
      containerSize.width > 0 &&
      sharedContentDimension.width > 0
    ) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerSize;
      if (viewType === ShareViewType.FitWindow) {
        const ratio = Math.min(
          containerWidth / width,
          containerHeight / height,
          1,
        );
        setShareViewSize({
          width: Math.floor(width * ratio),
          height: Math.floor(height * ratio),
        });
      } else if (viewType === ShareViewType.OriginalSize) {
        setShareViewSize({
          width,
          height,
        });
      }
    } else {
      setShareViewSize({ width: 0, height: 0 });
    }
  }, [isRecieveSharing, sharedContentDimension, containerSize, viewType]);

  useEffect(() => {
    if (
      shareViewSize.width > 0 &&
      (!isShallowEqual(shareViewSize, previousShareViewSize) ||
        (previousViewType !== viewType &&
          viewType === ShareViewType.OriginalSize))
    ) {
      mediaStream?.updateSharingCanvasDimension(
        shareViewSize.width,
        shareViewSize.height,
      );
    }
  }, [
    mediaStream,
    previousShareViewSize,
    shareViewSize,
    viewType,
    previousViewType,
  ]);

  useImperativeHandle(
    ref,
    () => {
      return {
        selfShareRef: selfShareViewRef.current,
      };
    },
    [],
  );

  useEffect(() => {
    onRecieveSharingChange(isRecieveSharing);
  }, [isRecieveSharing, onRecieveSharingChange]);

  return (
    <>
      <ShareBar ref={selfShareViewRef} />
      <ShareViewContainer
        ref={shareViewContainerRef}
        isRecieveSharing={isRecieveSharing}
        viewType={viewType}>
        <ShareViewViewport ref={shareViewViewportRef} viewType={viewType}>
          <canvas className="share-view-canvas" ref={shareViewRef} />
        </ShareViewViewport>
      </ShareViewContainer>
    </>
  );
});

export default ShareView;
