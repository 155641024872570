import MicOffIcon from "@mui/icons-material/MicOff";
import { styled, Typography, useTheme } from "@mui/material";
import { useRef } from "react";
import { useSelector } from "react-redux";

const AvatarWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isVideoOn" && prop !== "esLocal" && prop !== "style", // Evitar pasar esta prop al DOM
})(({ theme, isVideoOn, esLocal, style }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  pointerEvents: "visible",
  backgroundColor: !isVideoOn ? "rgba(0, 0, 0, 0.9)" : "transparent",
  border: !isVideoOn ? "solid 1px grey" : "",
  zIndex: esLocal ? 99 : "",
  ...style,
}));

const Etiqueta = styled("div", {
  shouldForwardProp: (prop) => prop !== "tieneViewportPocaAltura", // Evitar pasar esta prop al DOM
})(({ theme, tieneViewportPocaAltura }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  maxWidth: "100%",
  color: "white",
  backgroundColor: "rgba(42, 93, 247, 0.75)",
  border: "solid 2px rgba(42, 93, 247, 0.9)",
  borderRadius: tieneViewportPocaAltura
    ? "0px 0px 10px 0px"
    : "0px 10px 0px 0px",
  fontSize: tieneViewportPocaAltura ? "14px" : "18px",
  fontWeight: 500,
  left: 0,
  bottom: 0,
  zIndex: 15,
  padding: 5,
}));

const CenterName = styled(Typography)(({ theme }) => ({
  zIndex: 16,
  fontWeight: 700,
  color: "#fff",
  textAlign: "center",
}));

const Avatar = ({
  participant,
  style,
  tieneViewportPocaAltura,
  esParticipanteLocal,
}) => {
  const theme = useTheme();

  const { apellidoNombrePaciente, apellidoNombreProfesional } = useSelector(
    (state) => state.call.call.data,
  );

  const avatarRef = useRef(null);

  const etiquetaNombre =
    participant.displayName === "Paciente"
      ? apellidoNombrePaciente
      : apellidoNombreProfesional;
  const tieneCamaraEncendida = participant.bVideoOn;
  const tieneAudio = participant.audio === "computer";
  const audioDesactivado = participant.audio === "";
  const estaMuteado = participant.muted;
  const estaEnFailover = participant.isInFailover;

  return (
    <AvatarWrapper
      ref={avatarRef}
      style={style}
      isVideoOn={participant.bVideoOn}
      esLocal={esParticipanteLocal}>
      {(tieneCamaraEncendida ||
        (tieneAudio && estaMuteado) ||
        audioDesactivado ||
        estaEnFailover) && (
        <Etiqueta tieneViewportPocaAltura={tieneViewportPocaAltura}>
          {(tieneAudio && estaMuteado) || audioDesactivado ? (
            <MicOffIcon sx={{ color: theme.palette.primary.main }} />
          ) : null}
          {tieneCamaraEncendida && (
            <span>
              {etiquetaNombre.toUpperCase()} {esParticipanteLocal && " (Usted)"}
            </span>
          )}
        </Etiqueta>
      )}
      {!tieneCamaraEncendida && (
        <CenterName variant="h5">{etiquetaNombre.toUpperCase()}</CenterName>
      )}
    </AvatarWrapper>
  );
};

export default Avatar;
