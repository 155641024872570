import { Button, styled } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  alignSelf: "center",
  marginBottom: 30,
  marginTop: 30,
  boxShadow: "3px 5px 10px 0px grey",
  fontSize: 14,
  fontWeight: 500,
  border: `solid 1px ${theme.palette.primary.homeGradient2}`,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
    marginBottom: 35,
    marginTop: 10,
  },
}));

const ErrorButton = ({ text, icon, action, color = "primary" }) => {
  return (
    <StyledButton
      variant="contained"
      color={color}
      type="submit"
      startIcon={icon}
      onClick={action}>
      {text}
    </StyledButton>
  );
};

export default ErrorButton;
