import { styled } from "@mui/material";
import React from "react";
import { esNavegadorMobile } from "../../../../utils/layout";
import CameraButton from "./buttons/CameraButton";
import LeaveRoomButton from "./buttons/LeaveRoomButton";
import MicButton from "./buttons/MicButton";
import SwitchToScreenSharingButton from "./buttons/SwitchToScreenSharingButton";

// Styled container with sx equivalent
const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  bottom: 10,
  zIndex: 999,
});

const MediaControlsContainer = ({
  room,
  selfShareCanvas,
  deberiaEstarEnSalaEspera,
  zoomChat,
  selfViewRef,
}) => {
  // Check if the browser is mobile
  const esMobile = esNavegadorMobile();

  return (
    <Container>
      <MicButton room={room} />
      <CameraButton room={room} selfViewRef={selfViewRef}/>
      {!esMobile && (
        <SwitchToScreenSharingButton
          room={room}
          selfShareCanvas={selfShareCanvas}
          deberiaEstarEnSalaEspera={deberiaEstarEnSalaEspera}
        />
      )}
      <LeaveRoomButton room={room} zoomChat={zoomChat} />
    </Container>
  );
};

export default MediaControlsContainer;
