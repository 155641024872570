import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import ReplayIcon from "@mui/icons-material/Replay";
import { Typography, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import errorImage from "../../../assets/images/errorImage.png";
import { closeIframe } from "../../../utils/iframe";
import ErrorButton from "../errorButton/ErrorButton";

const ErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  background: `linear-gradient(to right, ${theme.palette.primary.errorGradient1}, ${theme.palette.primary.errorGradient2})`,
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  borderBottom: `1px solid ${theme.palette.primary.grey}`,
  "& a": {
    textDecoration: "none",
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderTop: `1px solid ${theme.palette.primary.grey}`,
  borderBottom: `1px solid ${theme.palette.primary.grey}`,
  [theme.breakpoints.down("md")]: {
    border: "none",
  },
}));

const ErrorImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: 620,
  },
  [theme.breakpoints.down("md")]: {
    width: 350,
  },
  [theme.breakpoints.down("sm")]: {
    width: 320,
  },
  [theme.breakpoints.down("xs")]: {
    width: 420,
  },
}));

const ErrorButtonsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  gap: 30,
});

const Error = () => {
  const call = useSelector((state) => state.call.call);
  const error = useSelector((state) => state.error);

  return (
    <ErrorContainer>
      <TextContainer>
        <ErrorImage src={errorImage} alt="error" />
        <ErrorOutlineIcon
          sx={{ fontSize: 30, color: "primary.dark", mt: { xs: 1, md: 0 } }}
        />
        <Typography
          variant="overline"
          sx={{
            margin: 1,
            fontSize: { xs: 12, md: 16 },
            fontWeight: 600,
            color: "primary.dark",
          }}>
          {!error.code
            ? "Algo ha salido mal..."
            : `${error.code} - ${error.message}`}
        </Typography>
      </TextContainer>
      <ErrorButtonsContainer>
        <ErrorButton
          text="Reintentar"
          icon={<ReplayIcon />}
          action={() => window.location.reload()}
        />
        {call.data?.tipoParticipante === 0 && (
          <ErrorButton
            icon={<LogoutIcon />}
            action={closeIframe}
            color="error"
            usarIconoEnBlanco
            text="Volver"
          />
        )}
      </ErrorButtonsContainer>
    </ErrorContainer>
  );
};

export default Error;
