import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { VideoQuality } from "@zoom/videosdk";
import React, { useCallback, useEffect, useState } from "react";
import CallButton from "../../../../commons/callButton/CallButton";
import { StylizeVideoCamera } from "../../../../styled/Camera";

const CameraButton = ({ room, selfViewRef }) => {
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const stream = room?.getMediaStream();

  // Función para iniciar el video
  const startVideo = useCallback(async () => {
    if (selfViewRef.current) {
      await stream.startVideo();
      let userVideo = await stream.attachVideo(
        room.getCurrentUserInfo().userId,
        VideoQuality.Video_1080P,
      );
      StylizeVideoCamera(userVideo);
      selfViewRef.current.appendChild(userVideo);
      setIsVideoStarted(true);
    }

  }, [stream, room, selfViewRef]);

  // Función para detener el video
  const stopVideo = useCallback(async () => {
    await stream.stopVideo();
    await stream.detachVideo(room.getCurrentUserInfo().userId).then((elements) => {
      if (elements) {
        if (Array.isArray(elements)) {
          elements.forEach((e) => e.remove());
        } else {
          elements.remove();
        }
      }
    });
    setIsVideoStarted(false);
  }, [stream, room]);

  // Alternar video (encendido/apagado)
  const handleToggleVideoButton = useCallback(async () => {
    if (!isVideoStarted) {
      await startVideo();
    } else {
      await stopVideo();
    }
  }, [isVideoStarted, startVideo, stopVideo]);

  // Activar automáticamente el video al cargar el componente
  useEffect(() => {
    const requestPermissionsAndStart = async () => {
      const permissions = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (permissions) {
        await startVideo();
      }
    };
    requestPermissionsAndStart();
  }, [startVideo]);

  return (
    <CallButton
      src={!isVideoStarted ? <NoPhotographyIcon /> : <CameraAltIcon />}
      action={handleToggleVideoButton}
      tooltipText="Cámara"
    />
  );
};

export default CameraButton;
