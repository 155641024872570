import { CircularProgress, Typography, styled } from "@mui/material";
import React from "react";

const OverlayContainer = styled("div")({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: 20,
  marginTop: 40,
  marginLeft: 32,
  color: theme.palette.primary.dark,
}));

const Overlay = () => {
  return (
    <OverlayContainer>
      <CircularProgress size={200} />
      <Subtitle variant="overline">Cargando...</Subtitle>
    </OverlayContainer>
  );
};

export default Overlay;
