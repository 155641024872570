import { Typography, styled } from "@mui/material";
import React from "react";
import logo from "../../assets/images/marcaAccess.png";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: `radial-gradient(circle, ${theme.palette.primary.homeGradient1}, ${theme.palette.primary.homeGradient2})`,
  height: "100vh",
  width: "100vw",
}));

const Image = styled("img")({
  width: 242,
  height: 60,
  borderBottom: "1px solid rgba(50, 78, 178, 0.332)",
  "@media (max-width: 801px)": {
    width: 170,
    height: 45,
  },
  "@media (max-width: 501px)": {
    width: 140,
    height: 35,
  },
});

const Title = styled(Typography)(({ theme }) => ({
  color: "white",
  fontWeight: 500,
  marginBottom: 50,
  textShadow:
    "3px 3px 6px rgba(65, 61, 61, 0.4), 1px 1px 2px rgba(0, 0, 0, 0.2)",
  [theme.breakpoints.down(801)]: {
    fontSize: 60,
  },
  [theme.breakpoints.down(501)]: {
    fontSize: 45,
  },
}));

const Home = () => {
  return (
    <Container>
      <Image src={logo} alt="logo access" />
      <Title variant="h1">TELEMEDICINA</Title>
    </Container>
  );
};

export default Home;
