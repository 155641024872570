import { Button, Icon, styled } from "@mui/material";
import React from "react";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "isLastButton", // Evitar pasar estas props al DOM
})(({ theme, disabled, isLastButton }) => ({
  borderRadius: 50,
  marginLeft: 10,
  marginRight: 10,
  ...(isLastButton
    ? {
        backgroundColor: "rgba(230, 5, 5, 0.652)",
        "&:hover": {
          backgroundColor: "rgba(230, 5, 5, 0.330)",
        },
      }
    : {
        background: disabled
          ? "rgba(250, 250, 250, 0.2)"
          : "rgba(255, 255, 255, 0.8)",
        "&:hover": {
          backgroundColor: disabled
            ? "rgba(250, 250, 250, 0.2)"
            : "rgba(255, 255, 255, 0.8)",
        },
      }),

  cursor: "pointer",
}));

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== "usarIconoEnBlanco", // Evitar pasar esta prop al DOM
})(({ usarIconoEnBlanco }) => ({
  color: usarIconoEnBlanco ? "white" : "black",
}));

const CallButton = ({
  disabled,
  action,
  src,
  usarIconoEnBlanco,
  leaveRoomButton,
}) => {
  return (
    <StyledButton
      aria-haspopup="true"
      onClick={action}
      disabled={disabled}
      variant="outlined"
      isLastButton={leaveRoomButton}>
      <StyledIcon as={src.type.type} usarIconoEnBlanco={usarIconoEnBlanco} />
    </StyledButton>
  );
};

export default CallButton;
