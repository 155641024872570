import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { ShareStatus } from "@zoom/videosdk";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { RoomContext } from "../context/roomContext";

const ScreenShareControlBar = styled("div")(({ theme }) => ({
  color: "#fff",
  padding: "10px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  borderRadius: "14px",
  backgroundColor: "rgba(100, 100, 100, 0.6)",
  top: "2vh",
  position: "fixed",
  width: "20%",
  left: "40%",
  zIndex: 20,
  [theme.breakpoints.down("sm")]: {
    left: "10vw",
    top: "75%",
    width: "80% !important",
  },
  "& .share-bar-canvas": {
    width: "95px",
    height: "55px",
    borderRadius: "5px",
    border: "1px solid #23d959",
  },
  "& button": {
    marginLeft: 10,
    backgroundColor: "rgba(42, 93, 247, 0.75)",
  },
}));

const ShareBar = forwardRef((props, ref) => {
  const zmClient = useContext(RoomContext);
  const mediaStream = zmClient.getMediaStream();
  const [status, setStatus] = useState(
    mediaStream?.getShareStatus() ?? undefined,
  );

  const onShareStatusChange = useCallback(() => {
    if (status !== mediaStream?.getShareStatus()) {
      setStatus(mediaStream?.getShareStatus());
    }
  }, [status, mediaStream]);

  useEffect(() => {
    zmClient.on("user-updated", onShareStatusChange);
    return () => {
      zmClient.off("user-updated", onShareStatusChange);
    };
  }, [zmClient, onShareStatusChange]);

  return (
    <Box
      sx={{
        display: status === ShareStatus.End ? "none" : "block",
      }}>
      <ScreenShareControlBar>
        {mediaStream?.isStartShareScreenWithVideoElement() ? (
          <video
            id={"ZOOM_WEB_SDK_SHARER_CANVAS"}
            className="share-bar-canvas"
            ref={ref}
          />
        ) : (
          <canvas
            id={"ZOOM_WEB_SDK_SHARER_CANVAS"}
            className="share-bar-canvas"
            ref={ref}
          />
        )}
        <Button
          variant="contained"
          onClick={() => mediaStream.stopShareScreen()}
          sx={{
            marginLeft: 1,
            backgroundColor: "rgba(42, 93, 247, 0.75)",
          }}>
          Dejar de compartir
        </Button>
      </ScreenShareControlBar>
    </Box>
  );
});

export default ShareBar;
