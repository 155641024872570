import LogoutIcon from "@mui/icons-material/Logout";
import { MenuItem, Popover, styled, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { closeIframe } from "../../../../../utils/iframe";
import CallButton from "../../../../commons/callButton/CallButton";

// Styled components for Popover and MenuItem
const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    width: "fit-content", // Ajusta el ancho del menú
    maxHeight: 120, // Ajusta la altura máxima del menú si hay muchas opciones
    overflowY: "auto", // Habilita el desplazamiento vertical si el menú es más alto que el máximo
  },
});

const PopoverItem = styled(MenuItem)({
  height: 40,
  border: "1px solid #f0e9e9",
});

const LeaveRoomButton = ({ room }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const esHost = room?.isHost();
  const esProfesional =
    room?.getCurrentUserInfo()?.userIdentity === "Profesional";
  const puedeFinalizarReunion = esHost && esProfesional;

  const handleFinalizarReunion = async () => {
    closeIframe();
    room.leave(true).then((res) => {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    });
  };

  const handleSalirReunion = async () => {
    closeIframe();
    room.leave().then((res) => {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {puedeFinalizarReunion ? (
        <div>
          <CallButton
            tooltipText="Salir de la Reunión"
            src={<LogoutIcon />}
            usarIconoEnBlanco={true}
            leaveRoomButton={true}
            action={Boolean(anchorEl) ? handleFinalizarReunion : handleClick}
          />
          <StyledPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}>
            <PopoverItem onClick={handleSalirReunion}>
              <Typography>Salir de la reunión</Typography>
            </PopoverItem>
            <Tooltip
              title="Todos los participantes saldrán!"
              dir="bottom"
              placement="bottom"
              arrow>
              <PopoverItem onClick={handleFinalizarReunion}>
                <Typography>Terminar reunión</Typography>
              </PopoverItem>
            </Tooltip>
          </StyledPopover>
        </div>
      ) : (
        <CallButton
          tooltipText="Salir de la Reunión"
          src={<LogoutIcon />}
          leaveRoomButton
          action={handleSalirReunion}
          usarIconoEnBlanco={true}
        />
      )}
    </>
  );
};

export default LeaveRoomButton;
